import React, { useEffect, useRef, useState } from 'react'
import { constants, useServices } from 'cng-web-lib'
import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls'

function fetchUserGroupCurrUser(fetchRecords, onSuccess) {
  console.log('fetchUserGroupCurrUser...')
  fetchRecords.execute(
    ForwardHubApiUrls.USER_GROUP_CURR_USER,
    {},
    (data) => {
        onSuccess(data)
       },
     (error) => {
       console.log(error)
     }
     )
}

const ForwardHubApi = Object.freeze({
  fetchUserGroupCurrUser
})

export default ForwardHubApi