import { BasePage as CngBasePage } from 'cng-web-lib'
import { CustomPage, useServices } from 'cng-web-lib'
import React, { useState, useEffect, memo } from 'react'
import { Box, Typography } from '@material-ui/core'
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar"
import Tab from "@material-ui/core/Tab";
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_ForwardHub'
import { components, useTranslation } from 'cng-web-lib'
import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls'

import OutboundTabs from './outboundTabs'

const { CngTabs } = components
function WrappedOutboundPageTabsPage(props) {

    return (

        <CngBasePage
            moduleTitle={'Outbound'}
            renderPage={(showSnackbar) => (
                <OutboundTabs showNotification={showSnackbar} {...props}/>
            )}
        />
    )
}



function WrappedOutboundPage(props) {

    return (

        <CngBasePage
            moduleTitle={'Outbound'}
            renderPage={(showSnackbar) => (
                <CustomPage
                    showNotification={showSnackbar}
                    fetchPageRequest={{
                        url: ForwardHubApiUrls.OUTBOUND_PAGE_UPLOAD
                    }}
                    {...props}
                />
            )}
        />
    )
}

function WrappedOutboundCreatePage(props) {
    const breadcrumbNameMap = useBreadcrumbNameMap()

    return (
        <CngBasePage
            moduleTitle={'Outbound'}
            renderPage={(showSnackbar) => (
                <CustomPage
                    showNotification={showSnackbar}
                    fetchPageRequest={{
                        url: ForwardHubApiUrls.OUTBOUND_PAGE_CREATE
                    }}
                    {...props}
                />
            )}
        />
    )
}

function WrappedOutboundSearchPage(props) {

    return (
        <CngBasePage
            moduleTitle={'Outbound'}
            renderPage={(showSnackbar) => (
                <CustomPage
                    showNotification={showSnackbar}
                    fetchPageRequest={{
                        url: ForwardHubApiUrls.OUTBOUND_PAGE_SEARCH
                    }}
                    {...props}
                />
            )}
        />
    )
}


export {
    WrappedOutboundPage as OutboundUploadPage,
    WrappedOutboundCreatePage as OutboundCreatePage,
    WrappedOutboundSearchPage as OutboundSearchPage,
    WrappedOutboundPageTabsPage as OutboundPageTabsPage
}