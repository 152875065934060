import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'

import ProdMgtViewForm from './ProdMgtViewForm'
import ProdMgtEditForm from './ProdMgtEditForm'
import { useParams } from 'react-router-dom'
import Namespace from 'src/constants/locale/Namespace'
import ForwardHubKeys from 'src/constants/locale/key/ForwardHub'

const { CngTabs } = components


function EditPage({ showNotification }) {
  const { id } = useParams()
  const { translate } = useTranslation([Namespace.FORWARD_HUB])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let prodMgt = translate(
      Namespace.FORWARD_HUB,
      ForwardHubKeys.TITLE_PROD_MGT
    )

    return {
      prodMgt
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h2> Edit Product</h2></Grid>
      <Grid item xs={12}>
        <ProdMgtEditForm
          showNotification={showNotification}
          id={id}
        />
      </Grid >
    </Grid >
  );
}

export default EditPage