import React, {useEffect, useState, memo} from "react";
import CommonUtils from 'src/views/common/utils/Utils'
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls'
import {  useServices, components } from 'cng-web-lib'
import { Card, CardContent, Tabs } from '@material-ui/core'
import CngCustomPage from 'src/views/common/ui/CngCustomPage'

const {
  table: { useDefaultNotification }
} = components


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {<Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper, 
    
  }
}));




const NormalTab = (props) => {
  return (  
     <CngCustomPage
         fetchPageRequest={{
               url: ForwardHubApiUrls.INBOUND_PAGE_NORMAL
          }}
          cardStyle = {{ border: "none", boxShadow: "none" }}
          showNotification={props.showNotification}
          iframeResizerOptions={
              {
                  heightCalculationMethod: 'lowestElement',
                  resizeFrom: 'child'
               }
           }
         {...props}
     />            
 );
};

const NormalTabMemo = memo(NormalTab);


 const LateTab = (props) => {
   return (
   
         <CngCustomPage
          fetchPageRequest={{
               url: ForwardHubApiUrls.INBOUND_PAGE_LATE
          }}
          cardStyle = {{ border: "none", boxShadow: "none" }}
          showNotification={props.showNotification}
          iframeResizerOptions={
              {
                  heightCalculationMethod: 'lowestElement',   
                  resizeFrom: 'child',
                
           }
          }
          {...props}
     />
           
 );
};

const LateTabMemo = memo(LateTab);


const ReinstateTab = (props) => {
  return (
 
     <CngCustomPage
         fetchPageRequest={{
               url: ForwardHubApiUrls.INBOUND_PAGE_REINSTATE
          }}
          showNotification={props.showNotification}
          cardStyle = {{ border: "none", boxShadow: "none" }}
          iframeResizerOptions={
              {
                  heightCalculationMethod: 'bodyOffset',
                  resizeFrom: 'child',
             
               }
           }
         {...props}
     />
   
                    
 );
};

const ReinstateTabMemo = memo(ReinstateTab);

export default function SimpleTabs({showNotification}) {
  const classes = useStyles();
  const [value, setValue] = useState(-1); //do not show any panel while retriving the show tab details
  const { fetchRecords } = useServices()
  const [showLate, setShowLate] = useState(false)
  const [showNormal, setShowNormal] = useState(false)
  const [showReinstate, setsShowReinstate] = useState(false)

  const [normalIndex, setNormalIndex] = useState(0)
  const [lateIndex, setLateIndex] = useState(1)
  const [ReinstateIndex, setReinstateIndex] = useState(2)
    

  const {
        error: showErrorNotification
  } = useDefaultNotification(showNotification)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  function setValueAndIndex(data){
    if(data.showNormal){
      setValue(0)
        //normal index remain as 0 
      if(!data.showLate){
        
        if(data.showReinstate){
          setReinstateIndex(1)
          setLateIndex(990) //do not display late
        }
        
      }
      
    }
    else{
      setNormalIndex(997) //do not display normal

      if(data.showLate){
        setValue(0)
        setLateIndex(0)
        if(data.showReinstate){
          setReinstateIndex(1)  //display late and reinstate tab
        }
      }else{
        if(data.showReinstate){
          setValue(0)
          setReinstateIndex(0)  //only show reinstate tab
        }
      }
    }

  
  }

  useEffect(() => {
        fetchRecords.execute(
        ForwardHubApiUrls.INBOUND_PAGE_SHOW_TAB,
        {},
        (data) => {

          setShowLate(data.showLate)
          setShowNormal(data.showNormal)
          setsShowReinstate(data.showReinstate)

          setValueAndIndex(data)
              

          console.log("success data1", value)
        },
            (error) => {
                console.log(error)
                showErrorNotification(CommonUtils.getErrorMessage(error))
            }
        )


  }, [])


  return (
    <div className={classes.root}>
       <Card>
        <CardContent color='primary'>

        <Tabs
          value={value}
          onChange={handleChange}
          textColor='secondary'
          variant='scrollable'
          
        >
          {showNormal &&
          <Tab label="Upload Inbound" style={{color:"black", textTransform: "none"}} {...a11yProps(0)} />
         }
          {showLate&&
          <Tab label="Upload Inbound Late" style={{color:"black", textTransform: "none"}} {...a11yProps(1)} />
         }
          {showReinstate &&
          <Tab label="Upload Inbound Reinstate" style={{color:"black", textTransform: "none"}} {...a11yProps(2)} />
         }
        </Tabs>
        </CardContent>
       
       
      <TabPanel value={value} index={normalIndex}>
        <NormalTabMemo />
      </TabPanel>
     <TabPanel value={value} index={lateIndex}>
        <LateTabMemo />
      </TabPanel>
     <TabPanel value={value} index={ReinstateIndex}>
        <ReinstateTabMemo/>
      </TabPanel>
      
      </Card>
    </div>
  )
}
