import DashLoadStatusFormProperties from './DashLoadStatusFormProperties'
import DashLoadStatusApiUrls from 'src/apiUrls/ForwardHubApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngEditForm }
} = components

function DashLoadStatusEditForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {
  // this is intentional
  }
}) {
  return (
    <CngEditForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <DashLoadStatusFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={DashLoadStatusFormProperties.formikProps}
      toClientDataFormat={DashLoadStatusFormProperties.toClientDataFormat}
      toServerDataFormat={DashLoadStatusFormProperties.toServerDataFormat}
      fetch={{
        url: DashLoadStatusApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
      update={{
        url: DashLoadStatusApiUrls.PUT
      }}
      id={id}
    />
  )
}

export default DashLoadStatusEditForm
