import { BasePage as CngBasePage } from 'cng-web-lib'
import CngCustomPage from 'src/views/common/ui/CngCustomPage'
import React, { useState, useEffect, memo } from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_ForwardHub'
import { components, useTranslation } from 'cng-web-lib'
import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls'
const { CngTabs } = components

const Upload = (props) => {
    return (
        <CngCustomPage
        showNotification={props.showNotification}
        fetchPageRequest={{
            url: ForwardHubApiUrls.JIT_PAGE_LOAD
        }}
        {...props}
    />            
  );
 };

const Enquiry = (props) => {
    return (
        <CngCustomPage
        showNotification={props.showNotification}
        fetchPageRequest={{
            url: ForwardHubApiUrls.JIT_PAGE_SEARCH
        }}
        iframeResizerOptions={
            {
            heightCalculationMethod: 'bodyOffset',
            sizeHeight:false,
            scrolling:true
            }
        }
        {...props}
    />            
  );
 };

const EnquiryMemo = memo(Enquiry);
const UploadMemo = memo(Upload);


function UploadPage({showNotification}) {
    return (
      <UploadMemo/>
    )
  }
function EnquiryPage({showNotification}) {
  return (
    <EnquiryMemo/>
  )
}

function WrappedJITRequestPage(props) {

    return (
        <CngBasePage
            moduleTitle={'Submit'}
            renderPage={(showSnackbar) => (
                <UploadPage showNotification={showSnackbar} {...props}/>
            )}
        />
    )
}

function WrappedJITEnquiryPage(props) {

    return (
        <CngBasePage
            moduleTitle={'Enquiry'}
            renderPage={(showSnackbar) => (
                <EnquiryPage showNotification={showSnackbar} {...props}/>
            )}
        />
    )
}

export {
    WrappedJITRequestPage as JITRequestPage,
    WrappedJITEnquiryPage as JITEnquiryPage
}