import CommonConfigApiUrls from 'src/apiUrls/CommonConfigApiUrls'
import CommonConfigFormProperties from './CommonConfigFormProperties'
import React from 'react'
import { components } from 'cng-web-lib'

const {
    form: { CngEditForm }
} = components

function CommonConfigEditForm({
    showNotification,
    id,
    onFetchPreSuccess = () => { 
        //do nothing
    }
}) {

    return (
        <CngEditForm
            fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
            showNotification={showNotification}
            bodySection={
                <CommonConfigFormProperties.Fields
                    showNotification={showNotification}
                />
            }
            formikProps={CommonConfigFormProperties.formikProps}
            toClientDataFormat={CommonConfigFormProperties.toClientDataFormat}
            toServerDataFormat={CommonConfigFormProperties.toServerDataFormat}
            fetch={{
                url: CommonConfigApiUrls.GET,
                onPreSuccess: onFetchPreSuccess
            }}
            update={{
                url: CommonConfigApiUrls.PUT
            }}
            id={id}
        />
    )
}

export default CommonConfigEditForm
