import ProdMgtFormProperties from './ProdMgtFormProperties'
import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngViewForm }
} = components

function ProdMgtViewForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {
    // default empty func placeholder
  }
}) {
  return (
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <ProdMgtFormProperties.Fields
          disabled={true}
          showNotification={showNotification}
        />
      }
      formikProps={ProdMgtFormProperties.formikProps}
      toClientDataFormat={ProdMgtFormProperties.toClientDataFormat}
      toServerDataFormat={ProdMgtFormProperties.toServerDataFormat}
      fetch={{
        id: id,
        url: ForwardHubApiUrls.PROD_MGT_GET,
        onPreSuccess: onFetchPreSuccess
      }}
    />
  )
}

export default ProdMgtViewForm
