import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useServices,
  useTranslation
} from 'cng-web-lib'

import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls'
import React from 'react'
import AdminlogKeys from 'src/constants/locale/key/Adminlog'
import Namespace from 'src/constants/locale/Namespace'
import fileDownload from 'js-file-download'

import Moment from 'moment';
import 'moment-timezone';

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateRangeFilter: CngDateRangeFilter,
    DateTimeRangeFilter: CngDateTimeRangeFilter,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function DashboardTablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const { securedSendRequest } = useServices()
  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.ADMINLOG
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let adminlog = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: adminlog
      }
    )
    let createdBy = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.CREATED_BY_NAME
    )
    
    let exportedDate = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.EXPORTED_DATE
    )
    let createdDate = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.UPDATED_DATE
    )
    let fileContent = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.FILE_CONTENT
    )
    let fileName = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.FILE_NAME
    )
    let fileSize = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.FILE_SIZE
    )
    let partyId = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.PARTY_ID
    )
    let customerGroupCode = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.CUSTOMER_GROUP_CODE
    )
    let scheme = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.SCHEME
    )
    let partyUserGroup = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.PARTY_USER_GROUP
    )
    let fileContentDateCreated = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.FILE_CONTENT_DATE_CREATED
    )
    let fileContentFilename = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.FILE_CONTENT_FILENAME
    )
    let fileContentFilesize = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.FILE_CONTENT_FILESIZE
    )
    let responseDateCreated = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.RESPONSE_DATE_CREATED
    )
    let responseFilename = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.RESPONSE_FILENAME
    )
    let responseFilesize = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.RESPONSE_FILESIZE
    )
    let response = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.RESPONSE
    )
    let orgCode = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.ORG_CODE
    )
    let apiResponse = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.API_RESPONSE
    )
    let action = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.ACTION
    )
    let outboundRefN = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.OUTBOUND_REF_N
    )
    let apiUrl = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.API_URL
    )
    let outbndInstrUpldId = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.OUTBND_INSTR_UPLD_ID
    )
    let prodCategoryCode = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.PROD_CATEGORY_CODE
    )
    let prodCode = translate(
      Namespace.ADMINLOG,
      AdminlogKeys.PROD_CODE
    )

    return {
      prodCategoryCode,
      prodCode,
      adminlog,
      tableTitle,
      createdBy,
      createdDate,
      exportedDate,
      updatedBy,
      updatedDate,
      fileContent,
      fileName,
      fileSize,
      partyId,
      customerGroupCode,
      scheme,
      orgCode,
      partyUserGroup,
      fileContentDateCreated,
      fileContentFilename,
      fileContentFilesize,
      responseDateCreated,
      responseFilename,
      responseFilesize,
      response,
      apiResponse,
      action,
      outboundRefN,
      apiUrl,
      outbndInstrUpldId
    }
  }


   function downloadFile(rowid,fileName) {
      const headers = {
        'Content-Type': `application/json`
      }
      const body={"id":rowid}

      downloadMediaFile(fileName, headers,body, (error) =>
      notification.error('Error when downloading file', error)
      )
    }



  	  function downloadMediaFile(fileName, headers, body, error) {
      return securedSendRequest.execute(
        'post',
        `${ForwardHubApiUrls.ADMIN_LOG_DASHBOARD_DOWNLOAD}`,
        body,
        (res) => {
          fileDownload(res.data, fileName)
          return res
        },
        undefined,
        body,
        {
          responseType: 'blob'
        }
      )
    }

    function formatDateTimeStr(timestamp) {
        let moment = Moment(timestamp) // hardcode to use browser timezone

        let str = moment.format('DD/MM/YYYY HH:mm')
        return (str)
    }


  const columns = [
    {
      field: "createdDate",
      title: translatedTextsObject.exportedDate,
      type: "datetime",
      filterComponent: CngDateTimeRangeFilter,
      defaultSort: "desc",
           render: (rowData) => {
                           return (<div>{formatDateTimeStr(rowData.createdDate)}</div>)
                          }
    },
    {
      field: "loginId",
      title: "Login ID",
    },
    // {
    //   field: "updatedBy",
    //   title: translatedTextsObject.updatedBy,
    //   type: "numeric",
    // },
    // {
    //   field: "updatedDate",
    //   title: translatedTextsObject.updatedDate,
    //   type: "date",
    //   filterComponent: CngDateRangeFilter,
    // },
    {
      field: "customerGroupCode",
      title: translatedTextsObject.customerGroupCode,
    },
    {
      field: "orgCode",
      title: translatedTextsObject.orgCode,
    },
    {
      field: "productCategoryCode",
      title: translatedTextsObject.prodCategoryCode,
    },
    {
      field: "productCode",
      title: translatedTextsObject.prodCode,
    },
    // {
    //   field: "fileSize",
    //   title: translatedTextsObject.fileSize,
    //   type: "numeric",
    // },
    // {
    //   field: "partyId",
    //   title: translatedTextsObject.partyId,
    //   type: "numeric",
    // },
    
    // {
    //   field: "partyUserGroup",
    //   title: translatedTextsObject.partyUserGroup,
    // },
    // {
    //   field: "fileContentDateCreated",
    //   title: translatedTextsObject.fileContentDateCreated,
    //   type: "date",
    //   filterComponent: CngDateRangeFilter,
    // },
    {
      field: "fileContentFilename",
      title: "File Content",
      render: (rowData) => {

               return (<div style={{'text-decoration': 'underline', cursor: 'pointer'}} onClick = {() =>{
                downloadFile(rowData.id,rowData.fileContentFilename)


                }}>



               {rowData.fileContentFilename}</div>)


            }
    },
    // {
    //   field: "fileContentFilesize",
    //   title: translatedTextsObject.fileContentFilesize,
    //   type: "numeric",
    // },
    // {
    //   field: "responseDateCreated",
    //   title: translatedTextsObject.responseDateCreated,
    //   type: "date",
    //   filterComponent: CngDateRangeFilter,
    // },
    // {
    //   field: "responseFilename",
    //   title: translatedTextsObject.responseFilename,
    // },
    // {
    //   field: "responseFilesize",
    //   title: translatedTextsObject.responseFilesize,
    //   type: "numeric",
    // },
    // {
    //   field: "response",
    //   title: translatedTextsObject.response,
    // },
    // {
    //   field: "apiResponse",
    //   title: translatedTextsObject.apiResponse,
    // },
    // {
    //   field: "action",
    //   title: translatedTextsObject.action,
    // },
    // {
    //   field: "outboundRefN",
    //   title: translatedTextsObject.outboundRefN,
    // },
    // {
    //   field: "apiUrl",
    //   title: translatedTextsObject.apiUrl,
    // },
    // {
    //   field: "outbndInstrUpldId",
    //   title: translatedTextsObject.outbndInstrUpldId,
    //   type: "numeric",
    // }
  ]

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              columns={columns}
              //del={{ url: ForwardHubApiUrls.DELETE }}
              exportData={{ url: ForwardHubApiUrls.EXPORT }}
              fetch={{ url: ForwardHubApiUrls.ADMIN_LOG_DASHBOARD_GET }}
              idAccessor="id"
              notification={notification}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default DashboardTablePage
