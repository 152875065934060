import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import CommonConfigApiUrls from 'src/apiUrls/CommonConfigApiUrls'
import React from 'react'
import CommonConfigKeys from 'src/constants/locale/key/CommonConfig'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.COMMON_CONFIG
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let commonConfig = translate(
      Namespace.COMMON_CONFIG,
      CommonConfigKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: commonConfig
      }
    )
    let configFor = translate(
      Namespace.COMMON_CONFIG,
      CommonConfigKeys.CONFIG_FOR
    )
    let configValue1 = translate(
      Namespace.COMMON_CONFIG,
      CommonConfigKeys.CONFIG_VALUE1
    )
    let configValue2 = translate(
      Namespace.COMMON_CONFIG,
      CommonConfigKeys.CONFIG_VALUE2
    )
    let configValue3 = translate(
      Namespace.COMMON_CONFIG,
      CommonConfigKeys.CONFIG_VALUE3
    )
    let configDescription = translate(
      Namespace.COMMON_CONFIG,
      CommonConfigKeys.CONFIG_DESCRIPTION
    )

    return {
      commonConfig,
      tableTitle,
      configFor,
      configValue1,
      configValue2,
      configValue3,
      configDescription
    }
  }

  const columns = [
    {
      field: "configFor",
      title: translatedTextsObject.configFor,
    },
    {
      field: "configValue1",
      title: translatedTextsObject.configValue1,
    },
    {
      field: "configValue2",
      title: translatedTextsObject.configValue2,
    },
    {
      field: "configValue3",
      title: translatedTextsObject.configValue3,
    },
    {
      field: "configDescription",
      title: translatedTextsObject.configDescription,
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: CommonConfigApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: CommonConfigApiUrls.EXPORT }}
              fetch={{ url: CommonConfigApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_FOR"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
