import DashLoadStatusFormProperties from './DashLoadStatusFormProperties'
import DashLoadStatusApiUrls from 'src/apiUrls/ForwardHubApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngAddForm }
} = components

function DashLoadStatusAddForm({
  history,
  showNotification,
  onPostSubmitSuccess
}) {
  return (
    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <DashLoadStatusFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={DashLoadStatusFormProperties.formikProps}
      toClientDataFormat={DashLoadStatusFormProperties.toClientDataFormat}
      toServerDataFormat={DashLoadStatusFormProperties.toServerDataFormat}
      create={{
        url: DashLoadStatusApiUrls.POST,
        onPostSubmitSuccess: onPostSubmitSuccess
      }}
    />
  )
}

export default DashLoadStatusAddForm
