import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  DataFlattener, useTranslation
} from 'cng-web-lib'
import React from 'react'
import CommonConfigKeys from 'src/constants/locale/key/CommonConfig'
import Namespace from 'src/constants/locale/Namespace'
import makeValidationSchema from './CommonConfigMakeValidationSchema'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  configFor: "",
  configValue1: "",
  configValue2: "",
  configValue3: "",
  configDescription: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.COMMON_CONFIG)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let commonConfig = translate(
      Namespace.COMMON_CONFIG,
      CommonConfigKeys.TITLE
    )
    let configFor = translate(
      Namespace.COMMON_CONFIG,
      CommonConfigKeys.CONFIG_FOR
    )
    let configValue1 = translate(
      Namespace.COMMON_CONFIG,
      CommonConfigKeys.CONFIG_VALUE1
    )
    let configValue2 = translate(
      Namespace.COMMON_CONFIG,
      CommonConfigKeys.CONFIG_VALUE2
    )
    let configValue3 = translate(
      Namespace.COMMON_CONFIG,
      CommonConfigKeys.CONFIG_VALUE3
    )
    let configDescription = translate(
      Namespace.COMMON_CONFIG,
      CommonConfigKeys.CONFIG_DESCRIPTION
    )

    return {
      commonConfig,
      configFor,
      configValue1,
      configValue2,
      configValue3,
      configDescription
    }
  }

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.commonConfig} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.configFor}>
            <CngTextField
              name="configFor"
              label={translatedTextsObject.configFor + " *"}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.configValue1}>
            <CngTextField
              name="configValue1"
              label={translatedTextsObject.configValue1}
              disabled={disabled}
              multiline
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.configValue2}>
            <CngTextField
              name="configValue2"
              label={translatedTextsObject.configValue2}
              disabled={disabled}
              multiline
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.configValue3}>
            <CngTextField
              name="configValue3"
              label={translatedTextsObject.configValue3}
              disabled={disabled}
              multiline
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.configDescription}>
            <CngTextField
              name="configDescription"
              label={translatedTextsObject.configDescription}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

function toClientDataFormat(serverData) {
  return DataFlattener.parse(serverData);
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
