import makeValidationSchema from './DashLoadStatusMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import DashLoadStatusKeys from 'src/constants/locale/key/ForwardHub'
import { Grid } from '@material-ui/core'
import {
  components,
  DateTimeFormatter,
} from 'cng-web-lib'

const {
  form: {
    field: {
      CngTextField,
      CngDateField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  loadStatusId: 0,
  createdBy: 0,
  createdDate: "",
  updatedBy: 0,
  updatedDate: "",
  loadTask: "",
  loadStatus: "",
  lastRun: "",
  lastComplete: "",
  orgCode: "",
  customerGroupCode: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.DASH_LOAD_STATUS)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let loadStatusId = translate(
      Namespace.DASH_LOAD_STATUS,
      DashLoadStatusKeys.LOAD_STATUS_ID
    )
    let createdBy = translate(
      Namespace.DASH_LOAD_STATUS,
      DashLoadStatusKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.DASH_LOAD_STATUS,
      DashLoadStatusKeys.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.DASH_LOAD_STATUS,
      DashLoadStatusKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.DASH_LOAD_STATUS,
      DashLoadStatusKeys.UPDATED_DATE
    )
    let loadTask = translate(
      Namespace.DASH_LOAD_STATUS,
      DashLoadStatusKeys.LOAD_TASK
    )
    let loadStatus = translate(
      Namespace.DASH_LOAD_STATUS,
      DashLoadStatusKeys.LOAD_STATUS
    )
    let lastRun = translate(
      Namespace.DASH_LOAD_STATUS,
      DashLoadStatusKeys.LAST_RUN
    )
    let lastComplete = translate(
      Namespace.DASH_LOAD_STATUS,
      DashLoadStatusKeys.LAST_COMPLETE
    )
    let orgCode = translate(
      Namespace.DASH_LOAD_STATUS,
      DashLoadStatusKeys.ORG_CODE
    )
    let customerGroupCode = translate(
      Namespace.DASH_LOAD_STATUS,
      DashLoadStatusKeys.CUSTOMER_GROUP_CODE
    )

    return {
      loadStatusId,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      loadTask,
      loadStatus,
      lastRun,
      lastComplete,
      orgCode,
      customerGroupCode
    }
  }

	return (
    <Grid container spacing={3}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.loadStatusId}>
        <CngTextField
          name="loadStatusId"
          type="number"
          label={translatedTextsObject.loadStatusId}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.createdBy}>
        <CngTextField
          name="createdBy"
          type="number"
          label={translatedTextsObject.createdBy}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.createdDate}>
        <CngDateField
          name="createdDate"
          label={translatedTextsObject.createdDate}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.updatedBy}>
        <CngTextField
          name="updatedBy"
          type="number"
          label={translatedTextsObject.updatedBy}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.updatedDate}>
        <CngDateField
          name="updatedDate"
          label={translatedTextsObject.updatedDate}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.loadTask}>
        <CngTextField
          name="loadTask"
          label={translatedTextsObject.loadTask}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.loadStatus}>
        <CngTextField
          name="loadStatus"
          label={translatedTextsObject.loadStatus}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.lastRun}>
        <CngDateField
          name="lastRun"
          label={translatedTextsObject.lastRun}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.lastComplete}>
        <CngDateField
          name="lastComplete"
          label={translatedTextsObject.lastComplete}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.orgCode}>
        <CngTextField
          name="orgCode"
          label={translatedTextsObject.orgCode}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.customerGroupCode}>
        <CngTextField
          name="customerGroupCode"
          label={translatedTextsObject.customerGroupCode}
          disabled={disabled}
        />
      </CngGridItem>
    </Grid>
	)
}

function toClientDataFormat(serverData) {
  let localData = serverData;
  localData.createdDate = DateTimeFormatter.toClientDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toClientDate(
    localData.updatedDate
  );
  localData.lastRun = DateTimeFormatter.toClientDate(
    localData.lastRun
  );
  localData.lastComplete = DateTimeFormatter.toClientDate(
    localData.lastComplete
  );
  return localData;
}

function toServerDataFormat(localData) {
  localData.createdDate = DateTimeFormatter.toServerDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toServerDate(
    localData.updatedDate
  );
  localData.lastRun = DateTimeFormatter.toServerDate(
    localData.lastRun
  );
  localData.lastComplete = DateTimeFormatter.toServerDate(
    localData.lastComplete
  );
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
