import { BasePage as CngBasePage } from 'cng-web-lib'
import { CustomPage, useServices } from 'cng-web-lib'
import React, { useState, useEffect } from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_ForwardHub'
import { components, useTranslation } from 'cng-web-lib'
import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls'
import InboundTabs from './InboundTabs'

const { CngTabs } = components
function WrappedInboundPageTabsPage(props) {

    return (

        <CngBasePage
            moduleTitle={'Inbound'}
            renderPage={(showSnackbar) => (
                <InboundTabs showNotification={showSnackbar} {...props}/>
            )}
        />
    )
}


function WrappedInboundPage(props) {

    return (
        <CngBasePage
            moduleTitle={'Inbound'}
            renderPage={(showSnackbar) => (
                <CustomPage
                    showNotification={showSnackbar}
                    fetchPageRequest={{
                        url: ForwardHubApiUrls.INBOUND_PAGE_NORMAL
                    }}
                    {...props}
                />
            )}
        />
    )
}

function WrappedInboundLatePage(props) {

    return (
        <CngBasePage
            moduleTitle={'Inbound Late'}
            renderPage={(showSnackbar) => (
                <CustomPage
                    showNotification={showSnackbar}
                    fetchPageRequest={{
                        url: ForwardHubApiUrls.INBOUND_PAGE_LATE
                    }}
                    {...props}
                />
            )}
        />
    )
}

function WrappedInboundReinstatePage(props) {

    return (
        <CngBasePage
            moduleTitle={'Inbound Reinstate'}
            renderPage={(showSnackbar) => (
                <CustomPage
                    showNotification={showSnackbar}
                    fetchPageRequest={{
                        url: ForwardHubApiUrls.INBOUND_PAGE_REINSTATE
                    }}
                    {...props}
                />
            )}
        />
    )
}


export {
    WrappedInboundPage as InboundPage,
    WrappedInboundLatePage as InboundPageLate,
    WrappedInboundReinstatePage as InboundPageReinstate,
    WrappedInboundPageTabsPage as InboundTabPage
}