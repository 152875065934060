import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import CompanyForUserGroupApiUrls from 'src/apiUrls/CompanyForUserGroupApiUrls'
import React from 'react'
import CompanyForUserGroupKeys from 'src/constants/locale/key/CompanyForUserGroup'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  CodeMaintenanceType
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.COMPANY_FOR_USER_GROUP
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let companyForUserGroup = translate(
      Namespace.COMPANY_FOR_USER_GROUP,
      "Company"
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: companyForUserGroup
      }
    )
    let partyId = translate(
      Namespace.COMPANY_FOR_USER_GROUP,
      CompanyForUserGroupKeys.PARTY_ID
    )

    return {
      companyForUserGroup,
      tableTitle,
      partyId
    }
  }

  const columns = [
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'nameOth',
      title: 'Name Other',
    },
    {
      field: 'createdDate',
      title: 'Created Date',
    },
    {
      field: 'status',
      title: 'Status',
      customLookup: () => {
        return fetchCodeMaintenanceLookup(CodeMaintenanceType.STATUS, () => {console.log("Code Maintenance result")}, [], 'descriptionEn', 'id')
      }
    }
  ];

//console.log(JSON.stringify(props))

	return (

    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
							fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: CompanyForUserGroupApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: CompanyForUserGroupApiUrls.EXPORT }}
              fetch={{ url: CompanyForUserGroupApiUrls.GET_BY_PARTY_SERVICE }}
              idAccessor='id'
              notification={notification}
              tableConfigurationCode='USER_GROUP_COMPANY'
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default TablePage
