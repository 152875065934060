import { BasePage as CngBasePage } from 'cng-web-lib'
import { CustomPage, useServices } from 'cng-web-lib'
import React, { useState, useEffect } from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_ForwardHub'
import { components, useTranslation } from 'cng-web-lib'
import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls'
import StockTabs from './StockTabs'

const { CngTabs } = components
function WrappedStockPageTabsPage(props) {

    return (

        <CngBasePage
            moduleTitle={'Stock Management'}
            renderPage={(showSnackbar) => (
                <StockTabs {...props} />
            )}
        />
    )
}




function WrappedUploadStockPage(props) {

    return (
        <CngBasePage
            moduleTitle={'Stock Upload Page'}
            renderPage={(showSnackbar) => (
                <CustomPage
                    showNotification={showSnackbar}
                    fetchPageRequest={{
                        url: ForwardHubApiUrls.STOCK_PAGE_UPLOAD
                    }}
                    {...props}
                />
            )}
        // extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}

function WrappedSearchStockPage(props) {

    return (
        <CngBasePage
            moduleTitle={'Stock Page'}
            renderPage={(showSnackbar) => (
                <CustomPage
                    showNotification={showSnackbar}
                    fetchPageRequest={{
                        url: ForwardHubApiUrls.STOCK_PAGE_SEARCH
                    }}
                    {...props}
                />
            )}
        // extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}



export {
    WrappedUploadStockPage as StockUploadPage,
    WrappedSearchStockPage as StockSearchPage,
    WrappedStockPageTabsPage as StockTabPage
}