import makeValidationSchema from './ProdMgtMakeValidationSearchSchema'
import ProdMgtFormProperties from './ProdMgtFormProperties'
import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls'
import { components, useServices } from 'cng-web-lib'
import ForwardHubTranslationText from '../shared/ForwardHubTranslationText'
import { Grid, Box } from '@material-ui/core'
import React, {useState, useEffect } from 'react'
import Api from '../shared/api'
import ApiParser from '../shared/apiParser'
import ResetButton from 'src/components/button/ResetButton'

const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    CngAddForm,
    field: {
      CngTextField,
      CngSelectField
    },
  },
  button: { CngPrimaryButton, CngSecondaryButton },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  productCode: "",
  prodShortName: "",
  productCategoryCode: "",
  orgCode: "",
  customerGroupCode: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function FormFields({
  history,
  showNotification
 // onClick
}) {

 const resetForm = () => {
    console.log('reset')
    console.log(DEFAULT_INITIAL_VALUES)

    const dataArr = Object.entries(DEFAULT_INITIAL_VALUES)
    dataArr.forEach(([key, val]) => {
      console.log("key: " + key + " val: " + val)
      setFieldValue(key, val)
    })
  }

  const tranObj = ForwardHubTranslationText()  
  const {fetchRecords} = useServices()
  const [orgCodes, setOrgCodes] = useState([])
  const [userGroups, setUserGroups] = useState([])
  const [menuCodeField, , { setValue: setMenuCodeValue }] = useField('orgCode')
  const [userGroupData, setUserGroupData] = useState([])
  const [showUserGroup, setShowUserGroup] = useState(true)
  
  const [, , { setValue: setOrgCode }] = useField('orgCode')
  const [, , { setValue: setCustomerGroupCode }] = useField('customerGroupCode')
  const [, , { setValue: setProductCode }] = useField('productCode')
  const [, , { setValue: setProdShortName }] = useField('prodShortName')
  const [, , { setValue: setProductCategoryCode }] = useField('productCategoryCode')

  useEffect(() => {
    Api.fetchUserGroupCurrUser(fetchRecords, onUserGroupLoadSuccess)
  },[])
  function onUserGroupLoadSuccess(d) {
      let data=ApiParser.parseUserGroupCurrUser(d)

      data.orgCodes.splice(0, 0, {"text":"<Select>", "value":""});
      data.userGroups.splice(0, 0, {"text":"<Select>", "value":""});
      setOrgCodes(data.orgCodes)
      setUserGroups(data.userGroups)
      setUserGroupData(data.rawData)
      if(data.userGroups.length <= 1) {
        setShowUserGroup(false)
      }
  }
  const onCompanyChange = (e) => {
    let company = e.target.value
    setCustomerGroupCode('')
    console.log("<><><><>" + JSON.stringify(userGroupData))
    let groups = ApiParser.getUserGroupByCompany(userGroupData, company)
    groups.splice(0, 0, {"text":"<Select>", "value":""});
    setUserGroups(groups) 
    setShowUserGroup(true)                         
    if(groups.length <= 1) {
      setShowUserGroup(false)
    }
  }

 const onUserGroupChange = (e) => {
     let userGroupCode = e.target.value
     let company = ApiParser.getCompanyByUserGroup(userGroupData, userGroupCode)
     setMenuCodeValue(company[0].text)

}
 

  const { submitForm, setFieldValue } = useFormikContext()
  const onClick = () => {
    submitForm()
  }

  return (
    <Box>
    <Grid container spacing={3}>
      <CngGridItem xs={12} sm={6}>
        <CngSelectField
            name="orgCode"
            type="string"
            label={tranObj.orgCode}
            items={orgCodes} 
            onChange={onCompanyChange}
            />
            
      </CngGridItem>
      {
        showUserGroup && (
        <CngGridItem xs={12} sm={6} >
          <CngSelectField
              name="customerGroupCode"
              type="string"
              label={tranObj.customerGroup}
              items={userGroups}
              onChange={onUserGroupChange}
              />
        </CngGridItem>
        )
      }
      <CngGridItem xs={12} sm={6} >
        <CngTextField
          name="productCode"
          label={tranObj.productCode}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} >
        <CngTextField
          name="prodShortName"
          label={tranObj.prodShortName}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} >
        <CngTextField
          name="productCategoryCode"
          label={tranObj.productCategoryCode}
        />
      </CngGridItem>
    </Grid>
    <Grid container style={{paddingTop:20}} justify="center">
    {/* onClick={onClick}  */}

        <CngPrimaryButton type='submit' style={{width:'30%'}}>Search</CngPrimaryButton>
      </Grid>
    </Box>
  )
}

function toClientDataFormat(serverData) {
  let localData = serverData;
  return localData;
}

function toServerDataFormat(localData) {
  return localData;
}

const ProdMgtSearchForm = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default ProdMgtSearchForm
