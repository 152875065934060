import React, {useEffect, useState, memo} from "react";
import CommonUtils from 'src/views/common/utils/Utils'
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls'
import {  useServices, components } from 'cng-web-lib'
import { Card, CardContent, Tabs } from '@material-ui/core'
import CngCustomPage from 'src/views/common/ui/CngCustomPage'

const {
  table: { useDefaultNotification }
} = components


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {<Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper, 
    
  }
}));




const UploadTab = (props) => {
  return (  
     <CngCustomPage
         fetchPageRequest={{
               url: ForwardHubApiUrls.STOCK_PAGE_UPLOAD
          }}
          cardStyle = {{ border: "none", boxShadow: "none" }}
          showNotification={props.showNotification}
          iframeResizerOptions={
              {
                  heightCalculationMethod: 'lowestElement',
                  resizeFrom: 'child'
               }
           }
         {...props}
     />            
 );
};

const UploadTabMemo = memo(UploadTab);


 const SearchTab = (props) => {
   return (
   
         <CngCustomPage
          fetchPageRequest={{
               url: ForwardHubApiUrls.STOCK_PAGE_SEARCH
          }}
          cardStyle = {{ border: "none", boxShadow: "none" }}
          showNotification={props.showNotification}
          iframeResizerOptions={
              {
                  heightCalculationMethod: 'bodyOffset',   
                  resizeFrom: 'child',
                
           }
          }
          {...props}
     />
           
 );
};

const SearchTabMemo = memo(SearchTab);



export default function SimpleTabs({showNotification}) {
  const classes = useStyles();
  const [value, setValue] = useState(-1); //do not show any panel while retriving the show tab details
  const { fetchRecords } = useServices()
  const [showSearch, setShowSearch] = useState(false)
  const [showUpload, setShowUpload] = useState(false)
 
  const [uploadIndex, setUploadIndex] = useState(0)
  const [searchIndex, setSearchIndex] = useState(1)


    

  const {
        error: showErrorNotification
  } = useDefaultNotification(showNotification)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  function setValueAndIndex(data){
    if(data.showUpload){
      setValue(data.showSearch?  1 : 0)
        //search index remain as 0
      
    }else{
      if(data.showSearch){
        setValue(0)
        setSearchIndex(0)
        setUploadIndex(999) //not show upload
      }
    }
      
  }

  useEffect(() => {
        fetchRecords.execute(
        ForwardHubApiUrls.STOCK_PAGE_SHOW_TAB,
        {},
        (data) => {

          setShowSearch(data.showSearch)
          setShowUpload(data.showUpload)
         

          setValueAndIndex(data)
              
        },
            (error) => {
                console.log(error)
                showErrorNotification(CommonUtils.getErrorMessage(error))
            }
        )

    }, [])


  return (
    <div className={classes.root}>
       <Card>
        <CardContent color='primary'>

        <Tabs
          value={value}
          onChange={handleChange}
          textColor='secondary'
          variant='scrollable'
          
        >
          
          {showUpload&&
          <Tab label="Upload Stock Management" style={{color:"black", textTransform: "none"}} {...a11yProps(1)} />
         }
         {showSearch &&
          <Tab label="Search Stock Management" style={{color:"black", textTransform: "none"}} {...a11yProps(0)} />
         }
         
        </Tabs>
        </CardContent>
       
       
      <TabPanel value={value} index={searchIndex}>
        <SearchTabMemo />
      </TabPanel>
     <TabPanel value={value} index={uploadIndex}>
        <UploadTabMemo />
      </TabPanel>
 
      
      </Card>
    </div>
  )
}
