import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'

import ProdMgtViewForm from './ProdMgtViewForm'
import ProdMgtAddForm from './ProdMgtAddForm'
import ProdMgtEditForm from './ProdMgtEditForm'
import Namespace from 'src/constants/locale/Namespace'
import ForwardHubKeys from 'src/constants/locale/key/ForwardHub'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      UiComponentKeys,
    },
  },
} = constants

const { CngTabs } = components

function AddPage({ history, showNotification }) {
  const [lockedOnFirstTab, setLockedOnFirstTab] = useState(true)
  const [prodMgtId, setProdMgtId] = useState('')
  const { translate } = useTranslation([
    Namespace.FORWARD_HUB,
    Namespace.UI_COMPONENT
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let prodMgt = translate(
      Namespace.FORWARD_HUB,
      ForwardHubKeys.TITLE_PROD_MGT
    )
    let tabLockedMessage = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Tabs.PLEASE_SUBMIT_FIRST,
      {
        title: prodMgt
      }
    )

    return {
      prodMgt,
      tabLockedMessage
    }
  }

  function makeProdMgtForm() {
    if (prodMgtId === '') {
      return (
        <ProdMgtAddForm
          history={history}
          showNotification={showNotification}
          onPostSubmitSuccess={(datum) => {
            setProdMgtId(datum.id)
            setLockedOnFirstTab(false)
          }}
        />
      )
    } else {
      return (
        <ProdMgtEditForm
          showNotification={showNotification}
          id={prodMgtId}
        />
      )
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h2> Add Product</h2></Grid>
      <Grid item xs={12}>
        {makeProdMgtForm()}
      </Grid>
    </Grid>
  )
}

export default AddPage