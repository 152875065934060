import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import DashUserLoadStatusApiUrls from 'src/apiUrls/ForwardHubApiUrls.js'
import React from 'react'
import DashUserLoadStatusKeys from 'src/constants/locale/key/ForwardHub'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateRangeFilter: CngDateRangeFilter,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.DASH_LOAD_STATUS
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
      let userId = translate(
        Namespace.DASH_LOAD_STATUS,
        DashUserLoadStatusKeys.DASH_USER_STATUS_USER_ID
      )
    let dashLoadStatus = translate(
      Namespace.DASH_LOAD_STATUS,
      DashUserLoadStatusKeys.DASH_USER_STATUS_TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: dashLoadStatus
      }
    )
    let loadStatusId = translate(
      Namespace.DASH_LOAD_STATUS,
      DashUserLoadStatusKeys.DASH_USER_STATUS_LOAD_STATUS_ID
    )
    let createdBy = translate(
      Namespace.DASH_LOAD_STATUS,
      DashUserLoadStatusKeys.DASH_USER_STATUS_CREATED_BY
    )
    let createdDate = translate(
      Namespace.DASH_LOAD_STATUS,
      DashUserLoadStatusKeys.DASH_USER_STATUS_CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.DASH_LOAD_STATUS,
      DashUserLoadStatusKeys.DASH_USER_STATUS_UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.DASH_LOAD_STATUS,
      DashUserLoadStatusKeys.DASH_USER_STATUS_UPDATED_DATE
    )
    let loadTask = translate(
      Namespace.DASH_LOAD_STATUS,
      DashUserLoadStatusKeys.DASH_USER_STATUS_LOAD_TASK
    )
    let loadStatus = translate(
      Namespace.DASH_LOAD_STATUS,
      DashUserLoadStatusKeys.DASH_USER_STATUS_LOAD_STATUS
    )
    let lastRun = translate(
      Namespace.DASH_LOAD_STATUS,
      DashUserLoadStatusKeys.DASH_USER_STATUS_LAST_RUN
    )
    let lastComplete = translate(
      Namespace.DASH_LOAD_STATUS,
      DashUserLoadStatusKeys.DASH_USER_STATUS_LAST_COMPLETE
    )
    let orgCode = translate(
      Namespace.DASH_LOAD_STATUS,
      DashUserLoadStatusKeys.DASH_USER_STATUS_ORG_CODE
    )
    let customerGroupCode = translate(
      Namespace.DASH_LOAD_STATUS,
      DashUserLoadStatusKeys.DASH_USER_STATUS_CUSTOMER_GROUP_CODE
    )

    return {
        userId,
      dashLoadStatus,
      tableTitle,
      loadStatusId,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      loadTask,
      loadStatus,
      lastRun,
      lastComplete,
      orgCode,
      customerGroupCode
    }
  }

  const columns = [
    {
      field: 'id',
      title: translatedTextsObject.loadStatusId,
      type: 'numeric',
    },
   {
      field: 'user_id',
      title: translatedTextsObject.userId,
      type: 'numeric',
    },
    {
      field: 'createdBy',
      title: translatedTextsObject.createdBy,
      type: 'numeric',
    },
    {
      field: 'createdDate',
      title: translatedTextsObject.createdDate,
      type: 'date',
      filterComponent: CngDateRangeFilter,
    },
    {
      field: 'updatedBy',
      title: translatedTextsObject.updatedBy,
      type: 'numeric',
    },
    {
      field: 'updatedDate',
      title: translatedTextsObject.updatedDate,
      type: 'date',
      filterComponent: CngDateRangeFilter,
    },
    {
      field: 'load_task',
      title: translatedTextsObject.loadTask,
    },
    {
      field: 'load_status',
      title: translatedTextsObject.loadStatus,
    },
    {
      field: 'last_run',
      title: translatedTextsObject.lastRun,
      type: 'date',
      filterComponent: CngDateRangeFilter,
    },
    {
      field: 'last_complete',
      title: translatedTextsObject.lastComplete,
      type: 'date',
      filterComponent: CngDateRangeFilter,
    },
    {
      field: 'org_code',
      title: translatedTextsObject.orgCode,
    },
    {
      field: 'customer_group_code',
      title: translatedTextsObject.customerGroupCode,
    }
  ];

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
							fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: DashUserLoadStatusApiUrls.DASH_USER_STATUS_DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: DashUserLoadStatusApiUrls.EXPORT }}
              fetch={{ url: DashUserLoadStatusApiUrls.DASH_USER_STATUS_GET }}
              idAccessor='id'
              notification={notification}
              //tableConfigurationCode='REPLACE_WITH_CORE_TABLE_CONFIG_CODE'
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default TablePage
