import CompanyForUserGroupFormProperties from './CompanyForUserGroupFormProperties'
import CompanyForUserGroupApiUrls from 'src/apiUrls/CompanyForUserGroupApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngAddForm }
} = components

function CompanyForUserGroupAddForm({
  history,
  showNotification,
  onPostSubmitSuccess
}) {
  return (
    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <CompanyForUserGroupFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={CompanyForUserGroupFormProperties.formikProps}
      toClientDataFormat={CompanyForUserGroupFormProperties.toClientDataFormat}
      toServerDataFormat={CompanyForUserGroupFormProperties.toServerDataFormat}
      create={{
        url: CompanyForUserGroupApiUrls.POST,
        onPostSubmitSuccess: onPostSubmitSuccess
      }}
    />
  )
}

export default CompanyForUserGroupAddForm
