import { BasePage as CngBasePage } from 'cng-web-lib'
import CngCustomPage from 'src/views/common/ui/CngCustomPage'
import React, { useState, useEffect, memo } from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_ForwardHub'
import { components, useTranslation } from 'cng-web-lib'
import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls'

const VolumeReport = (props) => {
    return (
        <CngCustomPage
        showNotification={props.showNotification}
        fetchPageRequest={{
            url: ForwardHubApiUrls.VOLUME_REPORT_PAGE
        }}
        iframeResizerOptions={
            {
            heightCalculationMethod: 'bodyOffset',
            sizeHeight:false,
            scrolling:true
            }
        }
        {...props}
    />            
  );
 };

const VolumeReportMemo = memo(VolumeReport);


function VolumeReportPage({showNotification}) {
    return (
      <VolumeReportMemo/>
    )
}

function WrappedVolumeReportPage(props) {
    return (
        <CngBasePage
            moduleTitle={'Volume Report'}
            renderPage={(showSnackbar) => (
                <VolumeReportPage showNotification={showSnackbar} {...props}/>
            )}
        />
    )
}


export {
    WrappedVolumeReportPage as VolumeReportPage
}