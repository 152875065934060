import { BasePage as CngBasePage } from 'cng-web-lib'
import React, { memo} from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_ForwardHub'
import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls'
import CngCustomPage from 'src/views/common/ui/CngCustomPage'

const DashboardEnhanced = (props) => {
    return (
    
          <CngCustomPage
           fetchPageRequest={{
                url: ForwardHubApiUrls.DASHBOARD_PAGE_ENHANCED
           }}
           showNotification={props.showNotification}
           iframeResizerOptions={
            {
            heightCalculationMethod: 'bodyOffset',
            sizeHeight:false,
            scrolling:true
            }
           }
           {...props}
      />
            
  );
 };
 const DashboardBasic = (props) => {
    return (
    
          <CngCustomPage
           fetchPageRequest={{
                url: ForwardHubApiUrls.DASHBOARD_PAGE_BASIC
           }}
           showNotification={props.showNotification}
           iframeResizerOptions={
            {
                heightCalculationMethod: 'bodyOffset',
                sizeHeight:false,
                scrolling:true
            }
           }
           {...props}
      />
            
  );
 };

const DashboardEnhancedMemo = memo(DashboardEnhanced);
const DashboardBasicMemo = memo(DashboardBasic);

function DashboardEnhancedPage({showNotification}) {
  return (
    <DashboardEnhancedMemo/>
  )
}
function DashboardBasicPage({showNotification}) {
    return (
        <DashboardBasicMemo/>
      )
}
function WrappedDashboardEnhancedPage(props) {
    return (
        <CngBasePage
            moduleTitle={'Dashboard'}
            renderPage={(showSnackbar) => (
                <DashboardEnhancedPage showNotification={showSnackbar} {...props}/>
            )}
        />
    )
}


function WrappedDashboardBasicPage(props) {

    return (
        <CngBasePage
            moduleTitle={'Dashboard Basic'}
            renderPage={(showSnackbar) => (
                <DashboardBasicPage showNotification={showSnackbar} {...props}/>
            )}
        />
    )
}


export {
    WrappedDashboardBasicPage as DashboardBasicPage,
    WrappedDashboardEnhancedPage as DashboardEnhancedPage,
}
