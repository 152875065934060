import { constants, Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  return Yup.object({
    configFor: Yup.string().required(requiredMessage),
    configValue1: Yup.string(),
    configValue2: Yup.string(),
    configValue3: Yup.string(),
    configDescription: Yup.string(),
  })
}

export default makeValidationSchema
