import { BasePage as CngBasePage } from 'cng-web-lib'
import CngCustomPage from 'src/views/common/ui/CngCustomPage'
import React, { memo } from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_ForwardHub'
import { components, useTranslation } from 'cng-web-lib'
import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls'
const { CngTabs } = components


const FTMRequest = (props) => {
    return (

        <CngCustomPage
                    showNotification={props.showNotification}
                    fetchPageRequest={{
                        url: ForwardHubApiUrls.FTM_PAGE_LOAD
                    }}
                    {...props}
                />
    )
}

const FTMEnquiry = (props) => {
    return (
        <CngCustomPage
                    showNotification={props.showNotification}
                    fetchPageRequest={{
                        url: ForwardHubApiUrls.FTM_PAGE_SEARCH
                    }}

                    iframeResizerOptions={
                        {
                        heightCalculationMethod: 'bodyOffset',
                        sizeHeight:false,
                        scrolling:true
                        }
                    }
                    {...props}
        />
    )
}

const FTMRequestMemo = memo(FTMRequest)
const FTMEnquiryMemo = memo(FTMEnquiry)

function FTMRequestPage({showNotification}) {
    return (
        <FTMRequestMemo/>
      )
}

function FTMEnquiryPage({showNotification}) {
    return (
        <FTMEnquiryMemo/>
      )
}


function WrappedFTMRequestPage(props) {

    return (
        <CngBasePage
            moduleTitle={'FTM Request'}
            renderPage={(showSnackbar) => (
                <FTMRequestPage showNotification={showSnackbar} {...props}/>
            )}
        />
    )
}

function WrappedFTMEnquiryPage(props) {

    return (
        <CngBasePage
            moduleTitle={'FTM Enquiry'}
            renderPage={(showSnackbar) => (
                <FTMEnquiryPage showNotification={showSnackbar} {...props}/>
            )}
        />
    )
}

export {
    WrappedFTMRequestPage as FTMRequestPage,
    WrappedFTMEnquiryPage as FTMEnquiryPage
}