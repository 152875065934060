import { BasePage as CngBasePage } from 'cng-web-lib'
import CngCustomPage from 'src/views/common/ui/CngCustomPage'
import React, { useState, useEffect } from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_ForwardHub'
import { components, useTranslation } from 'cng-web-lib'
import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls'
const { CngTabs } = components


function WrappedWarehouseTrackingRequestPage(props) {

    return (
        <CngBasePage
            moduleTitle={'Warehouse Tracking'}
            renderPage={(showSnackbar) => (
                <CngCustomPage
                    showNotification={showSnackbar}
                    fetchPageRequest={{
                        url: ForwardHubApiUrls.WAREHOUSETRACKING_PAGE_LOAD
                    }}
                    {...props}
                />
            )}
        />
    )
}

function WrappedWarehouseTrackingEnquiryPage(props) {

    return (
        <CngBasePage
            moduleTitle={'Warehouse Tracking Enquiry'}
            renderPage={(showSnackbar) => (
                <CngCustomPage
                    showNotification={showSnackbar}
                    fetchPageRequest={{
                        url: ForwardHubApiUrls.WAREHOUSETRACKING_PAGE_SEARCH
                    }}

                    iframeResizerOptions={
                        {
                        heightCalculationMethod: 'bodyOffset',
                        sizeHeight:false,
                        scrolling:true
                        }
                    }
                    {...props}
                />
            )}
        />
    )
}

export {
	WrappedWarehouseTrackingRequestPage as WarehouseTrackingRequestPage,
	WrappedWarehouseTrackingEnquiryPage as WarehouseTrackingEnquiryPage
}