import ProdMgtFormProperties from './ProdMgtFormProperties'
import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls'
import { components, constants } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngEditForm }
} = components

const {
  NotificationType
} = constants

function ProdMgtEditForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {
    // default empty func placeholder
  }
}) {
  return (
    <CngEditForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <ProdMgtFormProperties.Fields
          showNotification={showNotification}
          editForm={true}
        />
      }
      formikProps={ProdMgtFormProperties.formikProps}
      toClientDataFormat={ProdMgtFormProperties.toClientDataFormat}
      toServerDataFormat={ProdMgtFormProperties.toServerDataFormat}
      fetch={{
        url: ForwardHubApiUrls.PROD_MGT_GET,
        onPreSuccess: onFetchPreSuccess
      }}
      update={{
        url: ForwardHubApiUrls.PROD_MGT_PUT,
        // onError: (error) => {
        //   showNotification(NotificationType.ERROR, error.response.data);
        // }
      }}
      id={id}
    />
  )
}

export default ProdMgtEditForm
