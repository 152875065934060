import makeValidationSchema from './CompanyForUserGroupMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import CompanyForUserGroupKeys from 'src/constants/locale/key/CompanyForUserGroup'
import { Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'

const {
  form: {
    field: {
      CngTextField,
      CngSelectField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partyId: 0,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap, partyId }) {
  const { translate } = useTranslation(Namespace.COMPANY_FOR_USER_GROUP)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let partyIdLabel = translate(
      Namespace.COMPANY_FOR_USER_GROUP,
      CompanyForUserGroupKeys.PARTY_ID
    )

    return {
      partyIdLabel
    }
  }

	return (
    <Grid container spacing={3}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyId}>
        <CngSelectField
          name="partyId"
          type="number"
          label={translatedTextsObject.partyIdLabel}
          disabled={disabled}
          fetch={
            {
              url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
              textAccessor: "name",
              body: {
                id: partyId
              }
            }
          }
        />
      </CngGridItem>
    </Grid>
	)
}

function toClientDataFormat(serverData) {
  let localData = serverData;
  return localData;
}

function toServerDataFormat(localData) {
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
