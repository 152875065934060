import { BasePage as CngBasePage } from 'cng-web-lib'
import { CustomPage, useServices } from 'cng-web-lib'
import React, { useState, useEffect } from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_ForwardHub'
import { components, useTranslation } from 'cng-web-lib'
import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls'
import OutboundTablePage from './outbound/TablePage'
import InboundTablePage from './inbound/TablePage'
import DashboardTablePage from './dashboard/TablePage'
import StockMgtTablePage from './stockmgt/TablePage'

const { CngTabs } = components

function WrappedAdminLogPage(props) {
    const breadcrumbNameMap = useBreadcrumbNameMap()

    return (

        <CngBasePage
            moduleTitle={'Admin Log Page'}
            renderPage={(showSnackbar) => (
                <AdminLogTabs {...props} />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}
function AdminLogTabs(props) {
    const { showSnackbar } = props
    const { fetchRecords } = useServices()
    const [tabs, setTabs] = useState([])

    useEffect(() => {
        showTabs()
    }, [])

    function showTabs(data) {
        const tabs = []
        const tabOutbound = {
            tabName: "Outbound",
            tabContent: (
                <OutboundTablePage showNotification={showSnackbar} {...props} />                
            )
        }
        const tabInbound = {
            tabName: "Inbound",
            tabContent: (
                <InboundTablePage showNotification={showSnackbar} {...props} />
            )
        }
        const tabStockMgt = {
            tabName: "Stock Management",
            tabContent: (
                <StockMgtTablePage showNotification={showSnackbar} {...props} />                
            )
        }
        const tabDashboard = {
            tabName: "Dashboard",
            tabContent: (
                <DashboardTablePage showNotification={showSnackbar} {...props} />                
            )
        }

        tabs.push(tabDashboard)
        tabs.push(tabInbound)
        tabs.push(tabOutbound)
        tabs.push(tabStockMgt)

        setTabs(tabs)
    }

    return (
        <CngTabs
            headerColor='primary'
            tabs={tabs}
            lockedOnFirstTab={false}
            tabLockedMessage={"tabLockedMessage"}
        />
    )
}

export {
    WrappedAdminLogPage as AdminLogPage
}