import {
  components,
  useTranslation,
  constants
} from 'cng-web-lib'

import CompanyUserGroupFormProperties from './CompanyUserGroupFormProperties'
import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls.js'
import { Card, CardContent, Grid } from '@material-ui/core'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import CompanyForUserGroupKeys from 'src/constants/locale/key/CompanyForUserGroup'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'

const {
  table: {
      CngCrudTable,
      useDefaultNotification,
      useFetchCodeMaintenanceLookup,
    CngServerModeDialogFormTable,
    useFetchCustomLookup
  },
  button: { CngPrimaryButton, CngSecondaryButton }
} = components


function CompanyUserGroupCrudTable({
  history,
  showNotification,
  companyForUserGroupId,
  isViewOnly = false
}) {

const {
  filter: {
    EQUAL
  }
} = constants


    const { id } = useParams();

    console.log("load for id : " + id)

  const { translate } = useTranslation([Namespace.COMPANY_FOR_USER_GROUP])
  const translatedTextsObject = makeTranslatedTextsObject()

  const fetchCustomLookup = useFetchCustomLookup();

  let create = { url: ForwardHubApiUrls.POST }
  let update = { url: ForwardHubApiUrls.PUT }
  let del = { url: ForwardHubApiUrls.DELETE }
//  if (isViewOnly) {
//    create = undefined
//    update = undefined
//    del = undefined
//  }

  function makeTranslatedTextsObject() {
    let userGroupMasterId = translate(
      Namespace.COMPANY_FOR_USER_GROUP,
      CompanyForUserGroupKeys.CompanyUserGroup.USER_GROUP_MASTER_ID
    )
    let shipperRequestTypeId = translate(
      Namespace.COMPANY_FOR_USER_GROUP,
      CompanyForUserGroupKeys.CompanyUserGroup.SHIPPER_REQUEST_TYPE_ID
    )

    return {
      userGroupMasterId,
      shipperRequestTypeId
    }
  }

  const columns = [

    {
          field: 'id',
          title: 'ID',
       },
    {
        field: 'name',
        title: 'Name',
     }
  ]

console.log(">>>>" + companyForUserGroupId)

let addUrl = "/cal-forwardhub/dash-export-template/addTemplate/" + companyForUserGroupId
let editUrl = "/cal-forwardhub/dash-export-template/editTemplate/"

  const onEditButtonClick = (event, record) => {
    history.push(editUrl + record.id)
  }

  return (
   <Card>
            <CngCrudTable
           				fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                         columns={columns}
                         idAccessor='id'
                          del={{ url: ForwardHubApiUrls.DASH_EXPORT_TEMPLATE_DELETE }}
                          editRoute={addUrl}
                          history={history}
                         addRoute={addUrl}
                         onEditButtonClick={onEditButtonClick}
                        toClientDataFormat={(serverData) => {
                          return serverData;
                        }}
                        toServerDataFormat={(localDatum) => {
                          return localDatum;
                        }}
                        fetchFilters={[{field: 'party_id', operator: EQUAL, value: companyForUserGroupId}]}
                        fetch={{
                          url: ForwardHubApiUrls.DASH_EXPORT_TEMPLATE_GET
                        }}
                          authoriseActions={{
                                execute: (actions) => {
                                  return actions
                                }
                          }}
                       />


    </Card>
  )
}

export default CompanyUserGroupCrudTable
