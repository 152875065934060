import CompanyForUserGroupFormProperties from './CompanyForUserGroupFormProperties'
import CompanyForUserGroupApiUrls from 'src/apiUrls/CompanyForUserGroupApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngViewForm }
} = components

function CompanyForUserGroupEditForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {console.log("CompanyForUserGroup Edit Form")}
}) {
  return (
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <CompanyForUserGroupFormProperties.Fields
          disabled={true}
          showNotification={showNotification}
          partyId={id}
        />
      }
      formikProps={CompanyForUserGroupFormProperties.formikProps}
      toClientDataFormat={CompanyForUserGroupFormProperties.toClientDataFormat}
      toServerDataFormat={CompanyForUserGroupFormProperties.toServerDataFormat}
      fetch={{
        id: id,
        url: CompanyForUserGroupApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
      update={{
        url: CompanyForUserGroupApiUrls.PUT
      }}
      id={id}
    />
  )
}

export default CompanyForUserGroupEditForm
