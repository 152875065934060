import ForwardHubKeys from 'src/constants/locale/key/ForwardHub'
import Namespace from 'src/constants/locale/Namespace'
import { useTranslation, constants } from 'cng-web-lib'
const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

const ForwardHubTranslationText = () => {
  const { translate } = useTranslation([
    Namespace.FORWARD_HUB
  ])
  let prodMgt = translate(
    Namespace.FORWARD_HUB,
    ForwardHubKeys.TITLE_PROD_MGT
  )
  let productCode = translate(
    Namespace.FORWARD_HUB,
    ForwardHubKeys.PRODUCT_CODE
  )
  let tableTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Table.TITLE,
    {
      nameTitleised: prodMgt
    }
  )
  let productDesc = translate(
    Namespace.FORWARD_HUB,
    ForwardHubKeys.PRODUCT_DESC
  )
  let productCategoryCode = translate(
    Namespace.FORWARD_HUB,
    ForwardHubKeys.PRODUCT_CATEGORY_CODE
  )
  let productCategoryDesc = translate(
    Namespace.FORWARD_HUB,
    ForwardHubKeys.PRODUCT_CATEGORY_DESC
  )
  let dgClassCode = translate(
    Namespace.FORWARD_HUB,
    ForwardHubKeys.DG_CLASS_CODE
  )
  let handlingInstrCode = translate(
    Namespace.FORWARD_HUB,
    ForwardHubKeys.HANDLING_INSTR_CODE
  )
  let hsCode = translate(
    Namespace.FORWARD_HUB,
    ForwardHubKeys.HS_CODE
  )
  let orgCode = translate(
    Namespace.FORWARD_HUB,
    ForwardHubKeys.ORG_CODE
  )
  let customerGroupCode = translate(
    Namespace.FORWARD_HUB,
    ForwardHubKeys.CUSTOMER_GROUP_CODE
  )
  let customerGroup = translate(
    Namespace.FORWARD_HUB,
    ForwardHubKeys.CUSTOMER_GROUP
  )
  let prodShortName = translate(
    Namespace.FORWARD_HUB,
    ForwardHubKeys.PROD_SHORT_NAME
  )
  let cargoDesc = translate(
    Namespace.FORWARD_HUB,
    ForwardHubKeys.CARGO_DESC
  )
  let safetyStockLevel = translate(
    Namespace.FORWARD_HUB,
    ForwardHubKeys.SAFETY_STOCK_LEVEL
  )
  let searchProdCode = translate(
    Namespace.FORWARD_HUB,
    ForwardHubKeys.SEARCH_PROD_CODE
  )
  return {
    searchProdCode,
    prodMgt,
    tableTitle,
    productCode,
    productDesc,
    productCategoryCode,
    productCategoryDesc,
    dgClassCode,
    handlingInstrCode,
    hsCode,
    orgCode,
    customerGroupCode,
    customerGroup,
    prodShortName,
    cargoDesc,
    safetyStockLevel
  }
}

export default ForwardHubTranslationText