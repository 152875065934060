import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    loadStatusId: Yup.number(),
    createdBy: Yup.number(),
    createdDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    updatedBy: Yup.number(),
    updatedDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    loadTask: Yup.string(),
    loadStatus: Yup.string(),
    lastRun: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    lastComplete: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    orgCode: Yup.string(),
    customerGroupCode: Yup.string(),
  })
}

export default makeValidationSchema
