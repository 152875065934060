import React, { useEffect, useRef, useState } from 'react'
import { constants, useServices } from 'cng-web-lib'

import Api from './api'

const VALUE_ALL = "ALL"
export default class ForwardHubApiParser {
  static parseUserGroupCurrUser(data) {
      const orgCodes = Array.from(new Set(data.map(a => a.orgCode)))
                            .map(orgCode => {
                              return {text: orgCode, value: orgCode}
                            })
      let userGroups = data.map(d => {
        return {text: d.customerGroupName, value: d.customerGroupCode}
      })
      return  {
        orgCodes: orgCodes, 
        userGroups: userGroups,
        rawData: data
      }        
  }
  static getUserGroupByCompany(data, company) {

    let groups = [];

    if ( !company || company  === "" ) {
          groups = data.map(u => {
                                                   return {text: u.customerGroupName, value: u.customerGroupCode}
                                                 });
    } else {
          groups = data.filter(d => d.orgCode == company)
                              .map(u => {
                                return {text: u.customerGroupName, value: u.customerGroupCode}
                              })
    }
     return groups
  }

    static getCompanyByUserGroup(data, userGroupCode) {

      if ( !userGroupCode || userGroupCode  === "" ) {
            return "";
      } else {
            return data.filter(d => d.customerGroupCode == userGroupCode)
                                .map(u => {
                                  return {text: u.orgCode, value: u.customerGroupName}
                                })
      }
    }

}