import DashUserLoadStatusFormProperties from './DashUserLoadStatusFormProperties'
import DashUserLoadStatusApiUrls from 'src/apiUrls/ForwardHubApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngEditForm }
} = components

function DashUserLoadStatusEditForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {
    // this is intentional
  }
}) {
  return (
    <CngEditForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <DashUserLoadStatusFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={DashUserLoadStatusFormProperties.formikProps}
      toClientDataFormat={DashUserLoadStatusFormProperties.toClientDataFormat}
      toServerDataFormat={DashUserLoadStatusFormProperties.toServerDataFormat}
      fetch={{
        url: DashUserLoadStatusApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
      update={{
        url: DashUserLoadStatusApiUrls.PUT
      }}
      id={id}
    />
  )
}

export default DashUserLoadStatusEditForm
