import AddPage from './AddPage.js'
import { BasePage as CngBasePage } from 'cng-web-lib'
import { CustomPage, useServices } from 'cng-web-lib'
import EditPage from './EditPage.js'
import React from 'react'
import TablePage from './TablePage.js'
import ViewPage from './ViewPage.js'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_AccountSetup'
import { components, useTranslation } from 'cng-web-lib'
import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls'
import { useParams } from "react-router"



function WrappedTablePage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <TablePage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedAddPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <AddPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedEditPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <EditPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedViewPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <ViewPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}


function WrappedCreateDashTemplatePage(props) {
    const { id } = useParams();
    console.log(">>>>>"  + id)

    return (
        <CngBasePage
            moduleTitle={'Create Template'}
            renderPage={(showSnackbar) => (
                <CustomPage
                    showNotification={showSnackbar}
                    fetchPageRequest={{
                        url: ForwardHubApiUrls.DASH_EXPORT_TEMPLATE_LOAD_ADD + id
                    }}
                    {...props}
                />
            )}
        />
    )
}

function WrappedEditDashTemplatePage(props) {
    const { id } = useParams();
    console.log(">>>WrappedEditDashTemplatePage>>"  + id)
    return (
        <CngBasePage
            moduleTitle={'Edit Template'}
            renderPage={(showSnackbar) => (
                <CustomPage
                    showNotification={showSnackbar}
                    fetchPageRequest={{
                        url: ForwardHubApiUrls.DASH_EXPORT_TEMPLATE_LOAD_EDIT + id
                    }}
                    {...props}
                />
            )}
        />
    )
}



export {
  WrappedAddPage as AddPage,
  WrappedEditPage as EditPage,
  WrappedViewPage as ViewPage,
  WrappedTablePage as TablePage,
  WrappedCreateDashTemplatePage as CreateDashTemplatePage,
  WrappedEditDashTemplatePage as EditDashTemplatePage
}
