import makeValidationSchema from './ProdMgtMakeValidationSchema'
import { useTranslation, useServices } from 'cng-web-lib'
import React, {useState, useEffect } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ForwardHubKeys from 'src/constants/locale/key/ForwardHub'
import ForwardHubTranslationText from '../shared/ForwardHubTranslationText'
import { Grid } from '@material-ui/core'
import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls'
import Api from '../shared/api'
import ApiParser from '../shared/apiParser'
import CodeMasterApi from 'src/views/common/api/CodeMasterApi'
import {
  components,
} from 'cng-web-lib'

const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: {
      CngTextField,
      CngSelectField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  productCode: "",
  productDesc: "",
  productCategoryCode: "",
  productCategoryDesc: "",
  dgClassCode: "",
  handlingInstrCode: "",
  hsCode: "",
  orgCode: "",
  customerGroupCode: "",
  prodShortName: "",
  cargoDesc: "",
  safetyStockLevel: "50",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, editForm, shouldHideMap }) {
  const tranObj = ForwardHubTranslationText()

  const {fetchRecords} = useServices()
  const [orgCodes, setOrgCodes] = useState([])
  const [userGroups, setUserGroups] = useState([])
  const [userGroupData, setUserGroupData] = useState([])
  const [, orgCodeField, { setValue: setOrgCode }] = useField('orgCode')
  const [, , { setValue: setCustomerGroupCode }] = useField('customerGroupCode')
  const [handlingInstrCodes, setHandlingInstrCodes] = useState([])
  const textColor = { style: { color: '#263238' } }
  
  useEffect(() => {
    CodeMasterApi.fetchCodeMaster(fetchRecords, 'CAL_FWD_HANDLING', false, onLoadCodeMasterSuccess)
    Api.fetchUserGroupCurrUser(fetchRecords, onUserGroupLoadSuccess)
  },[])
  function onUserGroupLoadSuccess(d) {
    let data=ApiParser.parseUserGroupCurrUser(d)
    setOrgCodes(data.orgCodes)
    setUserGroupData(data.rawData)
    if (editForm) {
      console.log('orgCodeValue:', orgCodeField.value)
      console.log('orgCodeValue userGroupData:', data.rawData)
      let groups = ApiParser.getUserGroupByCompany(data.rawData, orgCodeField.value)
      console.log('orgCodeValue groups:', groups)
      setUserGroups(groups)
    } else {
      setUserGroups(data.userGroups)
    }     
  }
  const onCompanyChange = (e) => {
    let company = e.target.value
    let groups = ApiParser.getUserGroupByCompany(userGroupData, company)
    setUserGroups(groups)                          
    if(groups.length <=1) {
      setCustomerGroupCode(groups[0].value)
    }
  }
  function onLoadCodeMasterSuccess(data) {
    let sorted = data.sort((a,b) => a.descriptionEn.localeCompare(b.descriptionEn))
    let codes = sorted.map(d => {
                      return {text: d.descriptionEn, value: d.code}
                    })
    setHandlingInstrCodes(codes)
  }
  
	return (
    <Grid container spacing={3}>
    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.orgCode}>
        <CngSelectField
            name="orgCode"
            type="string"
            label={tranObj.orgCode + " *"}
            items={orgCodes} 
            onChange={onCompanyChange}
            disabled={disabled || editForm}
			InputProps={textColor}
        />
    </CngGridItem>
    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.customerGroupCode}>
      <CngSelectField
            name="customerGroupCode"
            type="string"
            label={tranObj.customerGroup + " *"}
            items={userGroups} 
            disabled={disabled || editForm}
			InputProps={textColor}
            />
    </CngGridItem>
    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.productCode}>
      <CngTextField
        name="productCode"
        label={tranObj.productCode + " *"}
        disabled={disabled || editForm}
		InputProps={textColor}
      />
    </CngGridItem>
    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.prodShortName}>
      <CngTextField
        name="prodShortName"
        label={tranObj.prodShortName + " *"}
        disabled={disabled}
		InputProps={textColor}
      />
    </CngGridItem>  
    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.productDesc}>
      <CngTextField
        name="productDesc"
        label={tranObj.productDesc}
        disabled={disabled}
		InputProps={textColor}
      />
    </CngGridItem>
    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.productCategoryCode}>
      <CngTextField
        name="productCategoryCode"
        label={tranObj.productCategoryCode}
        disabled={disabled}
		InputProps={textColor}
      />
    </CngGridItem>
    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.productCategoryDesc}>
      <CngTextField
        name="productCategoryDesc"
        label={tranObj.productCategoryDesc}
        disabled={disabled}
		InputProps={textColor}
      />
    </CngGridItem>
    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.hsCode}>
      <CngTextField
        name="hsCode"
        label={tranObj.hsCode}
        disabled={disabled}
		InputProps={textColor}
      />
    </CngGridItem>
    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.handlingInstrCode}>
      {/* <CngTextField
        name="handlingInstrCode"
        label={tranObj.handlingInstrCode}
        items={handlingInstrCodes}
        disabled={disabled}
      /> */}
       <CngSelectField
            name="handlingInstrCode"
            type="string"
            label={tranObj.handlingInstrCode}
            items={handlingInstrCodes}
            disabled={disabled}
			InputProps={textColor}
            />
    </CngGridItem>
    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoDesc}>
      <CngTextField
        name="cargoDesc"
        label={tranObj.cargoDesc}
        disabled={disabled}
		InputProps={textColor}
      />
    </CngGridItem>
    {/* <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.dgClassCode}>
      <CngTextField
        name="dgClassCode"
        label={tranObj.dgClassCode}
        disabled={disabled}
      />
    </CngGridItem> */}
    
    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.safetyStockLevel}>
      <CngTextField
        name="safetyStockLevel"
        type="number"
        label={tranObj.safetyStockLevel}
        disabled={disabled}
		InputProps={textColor}
      />
    </CngGridItem>
  </Grid>
	)
}

function toClientDataFormat(serverData) {
  let localData = serverData;
  return localData;
}

function toServerDataFormat(localData) {
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
