import CommonConfigApiUrls from 'src/apiUrls/CommonConfigApiUrls'
import CommonConfigFormProperties from './CommonConfigFormProperties'
import React from 'react'
import { components } from 'cng-web-lib'

const {
    form: { CngViewForm }
} = components

function CommonConfigViewForm({
    showNotification,
    id,
    onFetchPreSuccess = () => { 
        //do nothing
    }
}) {
    return (
        <CngViewForm
            fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
            showNotification={showNotification}
            bodySection={
                <CommonConfigFormProperties.Fields
                    disabled={true}
                    showNotification={showNotification}
                />
            }
            formikProps={CommonConfigFormProperties.formikProps}
            toClientDataFormat={CommonConfigFormProperties.toClientDataFormat}
            toServerDataFormat={CommonConfigFormProperties.toServerDataFormat}
            fetch={{
                id: id,
                url: CommonConfigApiUrls.GET,
                onPreSuccess: onFetchPreSuccess
            }}
        />
    )
}

export default CommonConfigViewForm
