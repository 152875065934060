import { Container, Grid, Paper } from '@material-ui/core'
import React from 'react'
import { components, useTranslation } from 'cng-web-lib'

import CompanyForUserGroupViewForm from './CompanyForUserGroupViewForm'
import CompanyForUserGroupEditForm from './CompanyForUserGroupEditForm'
import CompanyUserGroupCrudTable from './CompanyUserGroupCrudTable'
import { useParams } from 'react-router-dom'
import Namespace from 'src/constants/locale/Namespace'
import CompanyForUserGroupKeys from 'src/constants/locale/key/CompanyForUserGroup'
import { useHistory } from 'react-router-dom'

const { CngTabs } = components


function EditPage({ showNotification }) {
 const history = useHistory()
	const { id } = useParams()
  const { translate } = useTranslation([Namespace.COMPANY_FOR_USER_GROUP])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let companyForUserGroup = translate(
      Namespace.COMPANY_FOR_USER_GROUP,
      CompanyForUserGroupKeys.TITLE
    )
    let companyUserGroup = translate(
      Namespace.COMPANY_FOR_USER_GROUP,
      CompanyForUserGroupKeys.CompanyUserGroup.TITLE
    )

    return {
      companyForUserGroup,
      companyUserGroup
    }
  }


  return (
     <CompanyUserGroupCrudTable
                      history = {history}
                      showNotification={showNotification}
                      companyForUserGroupId={id}
                    />
  );
}

export default EditPage