import CommonConfigApiUrls from 'src/apiUrls/CommonConfigApiUrls'
import CommonConfigFormProperties from './CommonConfigFormProperties'
import React from 'react'
import { components } from 'cng-web-lib'

const {
    form: { CngAddForm }
} = components

function CommonConfigAddForm({
    history,
    showNotification,
    onPostSubmitSuccess
}) {
    return (
        
        <CngAddForm
            fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
            history={history}
            showNotification={showNotification}
            bodySection={
                <CommonConfigFormProperties.Fields
                    showNotification={showNotification}
                />
            }
            formikProps={CommonConfigFormProperties.formikProps}
            toClientDataFormat={CommonConfigFormProperties.toClientDataFormat}
            toServerDataFormat={CommonConfigFormProperties.toServerDataFormat}
            create={{
                url: CommonConfigApiUrls.POST,
                onPostSubmitSuccess: onPostSubmitSuccess             
            }}
        />
    )
}

export default CommonConfigAddForm
