import DashUserLoadStatusFormProperties from './DashUserLoadStatusFormProperties'
import DashUserLoadStatusApiUrls from 'src/apiUrls/ForwardHubApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngAddForm }
} = components

function DashUserLoadStatusAddForm({
  history,
  showNotification,
  onPostSubmitSuccess
}) {
  return (
    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <DashUserLoadStatusFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={DashUserLoadStatusFormProperties.formikProps}
      toClientDataFormat={DashUserLoadStatusFormProperties.toClientDataFormat}
      toServerDataFormat={DashUserLoadStatusFormProperties.toServerDataFormat}
      create={{
        url: DashUserLoadStatusApiUrls.POST,
        onPostSubmitSuccess: onPostSubmitSuccess
      }}
    />
  )
}

export default DashUserLoadStatusAddForm
