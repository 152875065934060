import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import * as ValidationRegex from 'src/views/forwardhub/shared/ValidationRegex'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )



  function validateWhiteSpace(value) {
    return /^.*\S.*$/i.test(value)
  }
  function validateHSCode(value) {
    return !value || /^[0-9.-]{1,9}$/i.test(value)
  }
  function validateNumberOnly(value) {
    return !value || (/^\d+$/i.test(value) && value > 0)
  }
  const validValue = "Please enter a valid value."
  const validLevel = "Valid level is number between 1 and 99999."
  const emojiNotAllowedMsg = "Emoji characters are not allowed"

  return Yup.object({
    productCode: Yup.string().max(30, 'Maximum 30 characters').required(requiredMessage)
      .test("productCode", validValue, value => validateWhiteSpace(value))
      .test("productCode", emojiNotAllowedMsg, (value) => ValidationRegex.emojiRegx.test(value)),
    productDesc: Yup.string().max(255, 'Maximum 255 characters')
      .test("productDesc", emojiNotAllowedMsg, (value) => ValidationRegex.emojiRegx.test(value)),
    productCategoryCode: Yup.string().max(30, 'Maximum 30 characters').test("productCategoryCode", validValue, value => validateWhiteSpace(value))
      .test("productCategoryCode", emojiNotAllowedMsg, (value) => ValidationRegex.emojiRegx.test(value)),
    productCategoryDesc: Yup.string().max(50, 'Maximum 50 characters')
      .test("productCategoryDesc", emojiNotAllowedMsg, (value) => ValidationRegex.emojiRegx.test(value)),
    dgClassCode: Yup.string(),
    handlingInstrCode: Yup.string().max(5, 'Maximum 5 characters'),
    hsCode: Yup.string().test("hsCode", "Please enter a valid HS code.", value => validateHSCode(value)).nullable(),
    orgCode: Yup.string().required(requiredMessage),
    customerGroupCode: Yup.string().required(requiredMessage),
    prodShortName: Yup.string().max(100, 'Maximum 100 characters').required(requiredMessage).test("prodShortName", validValue, value => validateWhiteSpace(value))
      .test("prodShortName", emojiNotAllowedMsg, (value) => ValidationRegex.emojiRegx.test(value)),
    cargoDesc: Yup.string().max(70, 'Maximum 70 characters')
      .test("cargoDesc", emojiNotAllowedMsg, (value) => ValidationRegex.emojiRegx.test(value)),
    //safetyStockLevel: Yup.number().min(1, validLevel).max(99999, validLevel),
    safetyStockLevel: Yup.string().min(1, validLevel).max(5, validLevel).test('safetyStockLevel', validLevel, value => validateNumberOnly(value)),
  })
}

export default makeValidationSchema
