import { Card, CardContent, Grid, Paper, Container, Box } from '@material-ui/core'
import {
  components,
  constants,
  useServices
} from 'cng-web-lib'
import CommonUtils from 'src/views/common/utils/Utils'
import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls'
import React, { useState, useEffect, useRef } from 'react'
import {v4 as uuid} from 'uuid'
import ForwardHubKeys from 'src/constants/locale/key/ForwardHub'
import Namespace from 'src/constants/locale/Namespace'
import ProdMgtSearchForm from './ProdMgtSearchForm'
import ProdMgtFormProperties from './ProdMgtFormProperties'
import ForwardHubTranslationText from '../shared/ForwardHubTranslationText'
import { transformToLookupColumn } from '../shared/MaterialTableUtils'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPrint, faTrash } from "@fortawesome/free-solid-svg-icons"
import Utils from 'src/views/common/utils/Utils'

const {
  button: { CngPrimaryButton, CngSecondaryButton },
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification
  },
  form: { CngViewForm }, 
  CngDialog
} = components

const {
  locale: {
    key: { UiComponentKeys },
    NotificationType
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props


    const { exportRecords, deleteRecord } = useServices()

    const defaultExport = {
        url: undefined,
        request: exportRecords
      }

  const notification = useDefaultNotification(showNotification)
  const tranObj = ForwardHubTranslationText()
  const [searchCriteria, setSearchCriteria] = useState({})
  const [shouldRender, setShouldRender] = useState(false)
  const [stateKey, setStateKey] = useState(uuid())
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const addRouteURL = `${pathname}/add`
  const [rowData, setRowData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  function onSearch(data) {
    let criteria = {
      productCode: data.productCode,
      prodShortName: data.prodShortName,
      productCategoryCode: data.productCategoryCode,
      orgCode: data.orgCode,
      customerGroupCode: data.customerGroupCode
    }
    setSearchCriteria(criteria)
    console.log('onSearch...criteria: ', criteria)
    setStateKey(uuid())
  }

   const exportClicked = (e) => {

        console.log("..................export")

  }

  console.log('stateKey: ', stateKey)

  const columns = [
    {
      field: 'productCode',
      title: tranObj.productCode
    },
    {
      field: 'prodShortName',
      title: tranObj.prodShortName
    },
    {
      field: 'productCategoryCode',
      title: tranObj.productCategoryCode
    },
    {
      field: 'hsCode',
      title: tranObj.hsCode
    },
    {
      field: 'customerGroupName',
      title: tranObj.customerGroupCode
    }
  ];


function performExport(
    type,
    filters,
    orderBy,
    orderDirection,
    exportColumns
  ) {

    let newColumnIndex = reArrangeColumn();
    let exportColumns2 = [];
    newColumnIndex.forEach((v, i) => {
      if (!isColumnHidden(v)) {
        exportColumns2.push(columns[v]);
      }
    });

  filters = [{column : "prodShortName", value : JSON.stringify(searchCriteria)}]
    return exportRecords.execute(
       { url: ForwardHubApiUrls.PROD_MGT_EXPORT }.url,
      {
        filters: [
          ...filters.map(({ column, value }) => {
            return {
              ...column,
              value: value
            }
          })
        ],
        sorts: orderBy
          ? [{ field: orderBy.field, direction: orderDirection }]
          : [],
        columns: exportColumns2,
        fileType: type,
        fileName: 'data.' + type.toLowerCase()
      },
      (data, headers) => {
            // intentional
      },
      (error) => {
        console.error(error)
        notification.error(error.message)
      }
    )
  }

  const { fetchRecords } = useServices()
  const [printFlag, setPrintFlag] = useState(0)
  const [orderColumnName, setorderColumnName] = useState()
  const [orderDesc, setorderDesc] = useState()
  const [columnIndex, setColumnIndex] = useState([0,1,2,3,4])
  const cngTableRef = useRef()
  const [pageNumber, setPageNumber] = useState(0)
  const [pageRowSize, setPageRowSize] = useState(10)

  function handleOrderChange(
    orderedColumnId, orderDirection
  ) {
    setorderDesc(orderDirection)
    setorderColumnName(columns[orderedColumnId]?.field)
    //when sorting order change, page will be reset to the first page
    setPageNumber(0)
  }
  

  function onChangePage(page){
 
    setPageNumber(page)
  }

  function onChangeRowsPerPage(pageSize){
   
    setPageRowSize(pageSize)
  }



 function reArrangeColumn(sourceIndex, destinationIndex) {

   let newColumnIno = []
  let tableRefColumn = cngTableRef?.current?.dataManager?.columns
  columns.forEach((val, index)=>{
   
    const matched = tableRefColumn.find(element =>element.field ==val.field )
    if(matched){
      let columnInfo = {"index": index,"order": matched.tableData?.columnOrder}
      newColumnIno.push(columnInfo)
    }
    
  })
    
     newColumnIno.sort((a, b) => a.order - b.order)
 
   let newColumnIndex = []
     newColumnIno.forEach((val)=>{
      newColumnIndex.push(val.index)
     })


     setColumnIndex(newColumnIndex)
	return newColumnIndex;
  }
  useEffect(
    () => {
      if(printFlag>0){
        getPrintContent();
      }
    },
    [printFlag]
  )

  function getPrintContent(){

    fetchRecords.execute(
      ForwardHubApiUrls.PROD_MGT_SEARCH,
      {
 
        customData: { 
              productCode: searchCriteria?.productCode,
              customerGroupCode: searchCriteria?.customerGroupCode,
              orgCode:  searchCriteria?.orgCode, 
              prodShortName:  searchCriteria?.prodShortName, 
              productCategoryCode:  searchCriteria?.productCategoryCode,
              baseFilterDTO:{
                    filterProperties: [],
                    filterType: 'AND',
                    sortProperties: Utils.isEmpty(orderColumnName)? []: [{sortField: orderColumnName, sortType: orderDesc}]
                    //page:pageNumber,
                    //size:pageRowSize
                  } 
        },
},
        (data) => {        
          printFromWindow(data.content)
        
        },
        (error) => {
          console.log(error)
        }
      )
  }
  function isColumnHidden(index){
   
    let tableRefColumn = cngTableRef?.current?.dataManager?.columns
    const matched = tableRefColumn.find(element =>element.field ==columns[index].field )
    if(matched){

      return matched.hidden?true:false
 
    }
  }
  function getHeader(index){
   
    return isColumnHidden(index) ? '' : '<th>' + columns[index].title + '</th>'
  }

  function getRow(element, index){
    return isColumnHidden(index)? '' : '<td>'+ Utils.parseString(element[columns[index].field]) +'</td>'
  }
  

  function printFromWindow(printContent){
    let printWindow = window.open('', '', 'height=800,width=1200');
    
   
  
    let table_start = ('<html><head><title>' + tranObj.tableTitle + '</title>  <style type = "text/css">    table {    font-family: arial, sans-serif;    border-collapse: collapse;    width: 100%;  }  td, th {   border: 1px solid #dddddd;   text-align: left;  }  </style>  </head>  <table>    <tr>')
    printWindow.document.write(table_start);
	printWindow.document.write('<th>S/N</th>');
    columnIndex.forEach((elem, i) => {
     
      let header = getHeader(elem)
      printWindow.document.write(header);
    })
   
    printContent.map((element,index) => {
      printWindow.document.write(' <tr>')
	  printWindow.document.write('<td>' + (++index) + '</td>');
      columnIndex.forEach((v) => {
        
        let row = getRow(element, v)
        printWindow.document.write(row);
      })
      printWindow.document.write(' </tr>')
    })
    
  
    let table_end = ('  </table> </html>') 
     printWindow.document.write(table_end);
    
    printWindow.document.close();
    printWindow.print();
  }

  function onPrintTable(event, rowData){
    console.log("...Print")
    reArrangeColumn()
    getSortOrder()
    setPrintFlag(printFlag+1)
   
  }

  function onDeleteRecord(event, rowData){
   
    setRowData(rowData)
    setIsLoading(true)
    setDeleteDialogOpen(true)
  }

  function getSortOrder(){
    //get sorting order before query

    let dataManager = cngTableRef?.current?.dataManager

    if (dataManager===undefined || dataManager.orderBy < 0) 
    { 
     
      setorderColumnName(null)

    }

    else{
      let orderBy = dataManager.orderBy
      setorderDesc(dataManager.orderDirection)
      setorderColumnName(columns[orderBy]?.field)


    }

  }


  function closeDeleteDialog() {
    setDeleteDialogOpen(false)
    setIsLoading(false)
  }

  const deleteRow = () =>{

    deleteRecord.execute(
      ForwardHubApiUrls.PROD_MGT_DELETE,
      rowData,
      (data) => {
        setIsLoading(false)
        //cngTableRef.current.onQueryChange()
        if(data.errorMessages===null){
          showSuccessNotification("Delete succeeded")
        }else{
          showErrorNotification(data.errorMessages)
        }
       setStateKey(uuid())
      },
      (error) => {
        console.log(error)
        setIsLoading(false)
        showErrorNotification(CommonUtils.getErrorMessage(error))
      }
    )

    setDeleteDialogOpen(false)
  }

  function getIcon(icon){
    return (  
    <FontAwesomeIcon
      {...props}
      icon={icon}
      size='xs'
      className='MuiSvgIcon-colorPrimary'
    />)
  }


  return (
    <Box>
      <Box mb={2}>
        <Card>
          <Box className='view-card-header'>{tranObj.searchProdCode}</Box>
          <Grid container justify="center" className='view-card-content'>
            <Grid item sm={6} xs={6}>
              <CngViewForm
                fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                showNotification={showNotification}
                bodySection={
                  <ProdMgtSearchForm.FormFields
                    disabled={false}
                    showNotification={showNotification}
                  //onClick={onSearch}
                  />
                }
                formikProps={{
                  ...ProdMgtSearchForm.formikProps,
                  onSubmit: onSearch
                }}
                toClientDataFormat={ProdMgtFormProperties.toClientDataFormat}
                toServerDataFormat={ProdMgtFormProperties.toServerDataFormat}
              />
            </Grid>
          </Grid>

        </Card>
      </Box>
      <Card>
        <Box className='view-card-header'>{tranObj.tableTitle} </Box>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
               <CngCrudTable
                {...props}
                tableRef={cngTableRef}
                tableKey='prodmgt'
                hasColumnsButton={true}
                fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                addRoute={addRouteURL}
                columns={columns}
                // del={{
                //   url: ForwardHubApiUrls.PROD_MGT_DELETE, onError: (error) => {
                //     showNotification(NotificationType.ERROR, error.response.data);
                //   }
                // }}
                editRoute={`${pathname}/edit`}
                exportData={{ url: ForwardHubApiUrls.PROD_MGT_EXPORT }}
                onExportButtonClick={performExport}
                fetchMode="FULL"
                // options={{
                //   exportButton: true,
                //   exportFileName: "export", //the name is to follow CG
                //   exportAllData: true
                // }}
                fetch={{ url: ForwardHubApiUrls.PROD_MGT_SEARCH }}
                key={stateKey}
                fetchCustomData={searchCriteria}
                idAccessor='id'
               showFilter={false}
                notification={notification}
                //tableConfigurationCode='REPLACE_WITH_CORE_TABLE_CONFIG_CODE'
                viewRoute={`${pathname}/view`}
                onOrderChange={handleOrderChange}
                //onColumnDragged={reArrangeColumn}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                showRefreshButton={false}
                isLoading={isLoading}
                actions={[
                  {
                    icon: (props) => (

                      getIcon(faPrint)

                    ),
                    tooltip: "Print",
                    isFreeAction: true,
                    onClick: (event, rowData) => {
                     onPrintTable(event, rowData)
                                         }

                  },

                  {
                    icon: (props) => (
                        getIcon(faTrash)
                    ),
                    tooltip: "Delete",
                    isFreeAction: false,
                    onClick: (event, rowData) => {
                      onDeleteRecord(event, rowData)
                    }

                  }
                ]}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <CngDialog
      // Delete confirmation dialog
      dialogTitle='Delete'
      dialogTitleId='delete-confirmation'
      dialogContent= 'Are you sure you want to delete this record?'
      dialogContentId='delete-confirmation-content'
      dialogAction={
        <>
          <CngSecondaryButton onClick={closeDeleteDialog}>Cancel</CngSecondaryButton>
          <CngPrimaryButton onClick={deleteRow} autoFocus>
            Delete
          </CngPrimaryButton>
        </>
      }
      open={deleteDialogOpen}
      />

    </Box>
  );
}

export default TablePage
