import ProdMgtFormProperties from './ProdMgtFormProperties'
import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls'
import { components, constants } from 'cng-web-lib'
import React, {useState} from 'react'

const {
  form: { CngAddForm }
} = components

const {
  NotificationType
} = constants

function ProdMgtAddForm({
  history,
  showNotification,
  onPostSubmitSuccess,
}) {
  return (
    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <ProdMgtFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={ProdMgtFormProperties.formikProps}
      toClientDataFormat={ProdMgtFormProperties.toClientDataFormat}
      toServerDataFormat={ProdMgtFormProperties.toServerDataFormat}
      create={{
        url: ForwardHubApiUrls.PROD_MGT_POST,
        onPostSubmitSuccess: onPostSubmitSuccess,
        // onError: (error, setShouldShowPrimaryButtonCircularProgress) => {
        //   //Overwrite onError to show Portnet API error messages
        //   showNotification(NotificationType.ERROR, error.response.data);
        //   setShouldShowPrimaryButtonCircularProgress(false);
        // }
      }}
    />
  )
}

export default ProdMgtAddForm
