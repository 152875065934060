import { BasePage as CngBasePage } from 'cng-web-lib'
import CngCustomPage from 'src/views/common/ui/CngCustomPage'
import React, { memo } from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_ForwardHub'
import { components, useTranslation } from 'cng-web-lib'
import ForwardHubApiUrls from 'src/apiUrls/ForwardHubApiUrls'
const { CngTabs } = components


const StockTranser = (props) => {
    return (
    
           <CngCustomPage
            showNotification={props.showNotificatio}
            fetchPageRequest={{
                url: ForwardHubApiUrls.STOCK_PAGE_TRANSFER
            }}
            {...props}
     />
    )
}

const StockTranserMemo = memo(StockTranser);

function StockTranserPage({showNotification}) {
    return (
        <StockTranserMemo/>
      )
}

function WrappedStockTransferPage(props) {
    return (
        <CngBasePage
            moduleTitle={'Stock Transfer'}
            renderPage={(showSnackbar) => (
                <StockTranserPage showNotification={showSnackbar} {...props}/>
            )}
        />
    )
}



export {
    WrappedStockTransferPage as StockTransferPage
}