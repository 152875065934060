import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'

import CompanyForUserGroupViewForm from './CompanyForUserGroupViewForm'
import CompanyForUserGroupAddForm from './CompanyForUserGroupAddForm'
import CompanyForUserGroupEditForm from './CompanyForUserGroupEditForm'
import CompanyUserGroupCrudTable from './CompanyUserGroupCrudTable'
import Namespace from 'src/constants/locale/Namespace'
import CompanyForUserGroupKeys from 'src/constants/locale/key/CompanyForUserGroup'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      UiComponentKeys,
    },
  },
} = constants

const { CngTabs } = components

function AddPage({ history, showNotification }) {
  const [lockedOnFirstTab, setLockedOnFirstTab] = useState(true)
  const [companyForUserGroupId, setCompanyForUserGroupId] = useState('')
  const { translate } = useTranslation([
    Namespace.COMPANY_FOR_USER_GROUP,
    Namespace.UI_COMPONENT
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let companyForUserGroup = translate(
      Namespace.COMPANY_FOR_USER_GROUP,
      CompanyForUserGroupKeys.TITLE
    )
    let companyUserGroup = translate(
      Namespace.COMPANY_FOR_USER_GROUP,
      CompanyForUserGroupKeys.CompanyUserGroup.TITLE
    )
    let tabLockedMessage = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Tabs.PLEASE_SUBMIT_FIRST,
      {
        title: companyForUserGroup
      }
    )

    return {
      companyForUserGroup,
      companyUserGroup,
      tabLockedMessage
    }
  }

  function makeCompanyForUserGroupForm() {
    if (companyForUserGroupId === '') {
      return (
        <CompanyForUserGroupAddForm
          history={history}
          showNotification={showNotification}
          onPostSubmitSuccess={(datum) => {
            setCompanyForUserGroupId(datum.id)
            setLockedOnFirstTab(false)
          }}
        />
      )
    } else {
      return (
        <CompanyForUserGroupEditForm
          showNotification={showNotification}
          id={companyForUserGroupId}
        />
      )
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <CompanyForUserGroupViewForm
              showNotification={showNotification}
              id={companyForUserGroupId}
            />
          </Container>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.companyForUserGroup,
              tabContent: makeCompanyForUserGroupForm()
            },
            {
              tabName: translatedTextsObject.companyUserGroup,
              tabContent: (
                <CompanyUserGroupCrudTable
                  showNotification={showNotification}
                  companyForUserGroupId={companyForUserGroupId}
                />
              )
            },
          ]}
          lockedOnFirstTab={lockedOnFirstTab}
          tabLockedMessage={translatedTextsObject.tabLockedMessage}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage